<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.name')"
            v-model="form.name"
            :placeholder="$t('placeholder.enter_name')"
        />
      </b-col>
    </b-row>
    <div class="row permissions-wrapper">
      <b-col md="3"></b-col>
      <div class="col-md-6">
        <b-form-group v-for="(permissions, category) in permissionsList" :key="category" :label="category">
          <b-form-checkbox-group
              v-model="form.permissions"
              :options="permissions"
              name="flavour-1a"
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppInput from '@/components/form-groups/AppInput';

export default {
  components: {
    AppInput
  },
  props: {
    role: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    form: {
      name: '',
      permissions: []
    }
  }),
  beforeMount() {
    this.$store.dispatch('rolesStore/GET_PERMISSIONS');
  },
  computed: {
    ...mapGetters({
      permissionsList: 'rolesStore/PERMISSIONS'
    })
  },
  watch: {
    role(data) {
      this.form.name = data.name;
      this.form.permissions = data.permissions.map(el => el.id);
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.permissions-wrapper::v-deep {
  .col-form-label {
    font-size: 17px;
  }
}
</style>